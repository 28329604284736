<script>
  export let text, img, alt, name;
</script>

<div
  class="flex flex-row items-center w-[100%] lg:w-[45%] mb-8 p-4 gap-8 shadow-lg"
>
  <div class="flex flex-col items-start">
    <p class="text-gray-500 text-xs mb-2">
      {text}
    </p>
    <p class="font-bold text-sm text-gray-800">{name}</p>
  </div>
  <img src={img} {alt} class="rounded-md w-[80px] h-[70px]" />
</div>

<style lang="postcss" global>
  @tailwind base;
  @tailwind components;
  @tailwind utilities;
</style>
