<script>
  export let text, img, alt;
</script>

<div class="flex flex-col items-center w-[80%] md:w-1/6 gap-6 mb-8">
  <img src={img} {alt} class="w-[100px] h-[100px] md:w-[150px] md:h-[150px]" />
  <p class="text-gray-600 text-center font-medium text-xs md:text-base">
    {text}
  </p>
</div>

<style lang="postcss" global>
  @tailwind base;
  @tailwind components;
  @tailwind utilities;
</style>
