<script>
  import { onMount } from "svelte";
  import Product from "../lib/Product.svelte";
  import Advantage from "../lib/Advantage.svelte";
  import Testimonal from "../lib/Testimonal.svelte";
  import Header from "../lib/header/Header.svelte";

  // const API_URL = "http://localhost:3333";
  // const PLATFORM_URL = "http://localhost:3000";
  const API_URL = "https://api.betaniamarques.com.br";
  const PLATFORM_URL = "https://plataforma.betaniamarques.com.br";

  let products = [];

  onMount(async () => {
    const res = await fetch(API_URL + "/products?public=true&page=0&limit=10", {
      method: "GET",
      credentials: "include",
    });
    const { data } = await res.json();

    products = data;
  });
</script>

<svelte:head>
  <title>Home</title>
</svelte:head>

<Header />
<section id="inicio">
  <div class="relative">
    <picture>
      <source srcset="fotoeditbet.jpeg" type="image/jpeg" />
      <img src="fotoeditbet.jpeg" alt="Welcome" />
    </picture>
    <div
      class="bg-gray-700 absolute w-full h-full top-0 bg-opacity-40 flex flex-row flex-wrap items-center justify-around"
    >
      <div class="w-full md:w-[50%] flex flex-col items-center">
        <h1 class="font-bold text-white text-5xl mt-14 md:mt-0">
          Betânia Marques
        </h1>
        <p class="font-bold text-white text-lg">
          Sua salinha de redação no computador
        </p>
      </div>
      <div class="w-full md:w-[50%] flex flex-col items-center">
        <div
          class="rounded-md w-[80%] absolute bottom-[-150px] shadow-lg md:sticky"
        >
          <div class="bg-gray-100 rounded-t-md w-full p-4 flex justify-around">
            <p class="text-lg text-gray-900 font-bold">Inscriçōes para 2024 abertas</p>
          </div>
          <div
            class="bg-white rounded-b-md w-full flex flex-col items-center justify-around py-4"
          >
            <!-- <p class="font-bold mb-2">Matriculas a partir de</p>
            <div class="flex flex-row items-end m-4">
              <p class="mb-2 mr-4 text-lg">12x de</p>
              <p class="mb-2 text-cyan-400 text-4xl">R$12.50</p>
            </div> -->
            <a
              href="https://docs.google.com/forms/d/1sPwUHFdxO982yqRMUGZicO22SXAD5JqqpI8WZ622vJM/viewform?edit_requested=true"
              class="rounded-full bg-amber-500 hover:bg-amber-600 w-[200px] p-2 text-white font-bold text-center"
            >
              Inscrever-se
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    id="sobre"
    class="flex flex-row flex-wrap justify-around items-center mt-[150px] md:mt-0 py-16 pt-[6rem]"
  >
    <Advantage
      alt="icone de graduação"
      text={"Aulas e modelos baseados no Manual de Redação do ENEM com foco no que você realmente precisa saber para fazer uma boa redação"}
      img={"graduation.png"}
    />
    <Advantage
      alt="icone de grafico"
      text={" Gráficos para acompanhar a evolução da nota de suas redações e gráficos que comparam a sua nota com a nota média da plataforma."}
      img={"chart.png"}
    />
    <Advantage
      alt="icone de ideia"
      text={"Correções feita por professores capacitados explicando detalhadamente todos os seus erros e com dicas de como melhorar."}
      img={"tip.png"}
    />
  </div>

  <div
    id="planos"
    class="bg-gray-100 w-full min-h-[90vh] p-8 md:p-12 flex flex-col justify-around items-center"
  >
    <h1 class="mt-[100px] text-4xl text-gray-600">Nossos planos</h1>
    <div class="w-full p-8 md:p-12 flex flex-row justify-around items-center">
      {#each products as product}
        <Product {product} />
      {/each}
    </div>
  </div>

  <div
    id="depoimentos"
    class="bg-white w-full min-h-[90vh] p-8 flex flex-col justify-between items-center pt-0"
  >
    <h1 class="mt-[100px] text-4xl text-gray-600">Depoimento dos alunos</h1>
    <div
      class="flex flex-row flex-wrap gap-8 w-full h-auto justify-around pt-12"
    >
      <Testimonal
        alt="foto da pessoa"
        name="Haline Dias"
        text={'"Meu primeiro ano de cursinho foi em 2018. Comecei em um cursinho popular do meu bairro, onde as aulas de redacao eram precárias. E depois continuei meus estudos no "Estude Vest" da Fumec, onde uma das professoras de redação era a Betânia. No começo encontrei dificuldades pela falta de prática, mas fiz todos os temas de redação propostos e, no decorrer das aulas, minha nota nas redações passou de 520 para 1000 no cursinho"'}
        img={"face3.jpeg"}
      />
      <Testimonal
        alt="foto da pessoa"
        name="Haline Dias"
        text={'"Nas aulas com a Betânia aprendemos como montar um brainstorming efetivo relacionando os argumentos mais consistentes e acima do senso comum. Além disso ela sempre foi muito paciente pra explicar de novo e ensinar novamente quando estávamos em duvida."'}
        img={"face4.jpeg"}
      />
      <Testimonal
        alt="foto da pessoa"
        name="Haline Dias"
        text={'"Sua aula foi bem objetiva no que realmente ia cair na redação do que precisávamos saber pra fazer uma boa prova, os conteúdos bem explicativos e simples. A sua explicação também foi simples, ajudou muito, eu tirei 800 na redação e isso foi uma surpresa, você deu ótimas dicas além da técnica e de entender como funcionava a redação. Em 4 aulas eu consegui dar uma mudada significativa nos textos que eu fiz, obrigado pela aula ❤"'}
        img={"face6.jpeg"}
      />
      <Testimonal
        alt="foto da pessoa"
        name="Haline Dias"
        text={'"Eu sempre reitero que não teria conseguido pontuar 940 na minha redação, com um tema tão imprevisível, sem a orientação da tia, ela foi minha anjinha de 2019, a mulher incrível que me deu o necessário para permear a maestria na produção textual. A melhor correção do mundo, a melhor orientadora!"'}
        img={"face7.jpeg"}
      />
    </div>
  </div>
</section>
<footer
  class="flex flex-col items-start w-full p-8 shadow-[0_-6px_6px_-6px_rgba(0,0,0,0.3)] px-4 md:px-[150px] "
>
  <div
    class="flex flex-row items-center w-full flex-wrap gap-8 justify-between"
  >
    <img src="logo3.png" class="w-[70px]" alt="logo" />
    <div>
      <p>Contato:</p>
      <p class="text-cyan-400">bmarquesredacao@hotmail.com</p>
    </div>
    <div>
      <p>Redes sociais:</p>
      <a
        href="https://www.instagram.com/marques_betania"
        target="_blank"
        class="fa fa-instagram text-lg"
      />
      <a
        href="https://www.youtube.com/BetaniaMarques"
        target="_blank"
        class="fa fa-youtube text-lg"
      />
    </div>
  </div>
  <p class="pt-8">
    © Copyright 2019 Betânia Marques - Todos os direitos reservados
  </p>
</footer>

<style lang="postcss" global>
  @tailwind base;
  @tailwind components;
  @tailwind utilities;
</style>
