<script>
</script>

<header
  class="fixed top-0 w-full flex flex-row items-center justify-between px-6 bg-white shadow-md z-50 py-2"
>
  <div class="h-[55px] md:h-[70px]">
    <a href="https://betaniamarques.com.br">
      <img src="logo.png" alt="Betania Marques" class="h-[55px] md:h-[70px]" />
    </a>
  </div>

  <nav class="flex flex-row gap-8">
    <ul class="flex-row gap-6 items-center hidden md:flex">
      <li>
        <a
          sveltekit:prefetch
          href="/#inicio"
          class="text-lg text-black font-bold">Inicio</a
        >
      </li>
      <li>
        <a
          sveltekit:prefetch
          href="/#sobre"
          class="text-lg text-black font-bold">Sobre</a
        >
      </li>
      <li>
        <a
          sveltekit:prefetch
          href="/#planos"
          class="text-lg text-black font-bold">Planos</a
        >
      </li>
      <li>
        <a
          sveltekit:prefetch
          href="/#depoimentos"
          class="text-lg text-black font-bold">Depoimentos</a
        >
      </li>
    </ul>
    <a
      sveltekit:prefetch
      href="https://plataforma.betaniamarques.com.br"
      rel="noopener noreferrer"
      target="_blank"
      class="text-md text-white font-bold bg-amber-500 p-2 px-4 rounded-md"
    >
      Login
    </a>
  </nav>
</header>

<style lang="postcss" global>
  @tailwind base;
  @tailwind components;
  @tailwind utilities;
</style>
