<script>
  import router from "page";
  import Home from "./routes/index.svelte";
  import Assinar from "./routes/assinar.svelte";

  let page;

  router("/", () => (page = Home));
  router("/assinar", () => (page = Assinar));

  router.start();
</script>

<svelte:component this={page} />

<style lang="postcss" global>
  @tailwind base;
  @tailwind components;
  @tailwind utilities;
</style>
