<script>
  export let product;
  let {
    id,
    title,
    sub_title,
    beneficies,
    days_to_add,
    points_to_add,
    price,
    access_videos,
  } = product;
</script>

<section
  class="flex flex-col w-full max-w-sm p-12 space-y-6 bg-white rounded-md shadow-md "
>
  <div class="flex-shrink-0">
    <span class="text-4xl font-medium tracking-tight text-green-500">
      R${Number(price)}
    </span>
  </div>

  <div class="flex-shrink-0 pb-6 space-y-2 border-b">
    <h2 class="text-2xl font-normal">
      {title}
    </h2>
    <p class="text-sm text-gray-400 ">{sub_title}</p>
  </div>
  <ul class="flex-1 space-y-4">
    {#each beneficies as benefit}
      <li class="flex items-start">
        <svg
          class="w-6 h-6 text-green-300"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
            clip-rule="evenodd"
          />
        </svg>
        <span class="ml-3 text-base font-medium">
          {benefit}
        </span>
      </li>
    {/each}
  </ul>
  <div class="flex-shrink-0 pt-4">
    <a
      href={"/assinar?id=" + id}
      class="text-center flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 w-full"
    >
      Comprar agora!
    </a>
  </div>
</section>

<style lang="postcss" global>
  @tailwind base;
  @tailwind components;
  @tailwind utilities;
</style>
