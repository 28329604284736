<script>
  import Header from "../lib/header/Header.svelte";
  // const API_URL = "http://localhost:3333";
  // const PLATFORM_URL = "http://localhost:3000";
  const API_URL = "https://api.betaniamarques.com.br";
  const PLATFORM_URL = "https://plataforma.betaniamarques.com.br";

  const register = async (data) => {
    if (
      data.email.trim() === "" ||
      data.name.trim() === "" ||
      data.cpf.trim() === "" ||
      data.password.trim() === "" ||
      data.passwordConfirm.trim() === ""
    ) {
      error = "Preencha todos os campos";
      return;
    }

    if (data.password.length < 8) {
      error = "A senha deve ter no mínimo 8 caracteres";
      return;
    }

    if (data.password !== data.passwordConfirm) {
      error = "As senhas não conferem";
      return;
    }

    if (
      !(
        data.gender === "Masculino" ||
        data.gender === "Feminino" ||
        data.gender === "Outro"
      )
    ) {
      error = "Selecione um gênero";
      return;
    }

    if (data.cpf.length < 11) {
      error = "Insira um CPF válido";
      return;
    }

    const product = window.location?.search?.split("=")[1];

    if (!product) {
      error =
        "Ocorreu um plano ao identificar o plano. Por favor, tente escolher o plano novamente.";
      return;
    }

    loading = true;
    const res = await fetch(API_URL + "/auth/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(data),
    });

    const { error: resError } = await res.json();

    if (resError) {
      loading = false;
      error = resError;
      return;
    }

    const res2 = await fetch(API_URL + "/paypal", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ product }),
    });

    const { error: error2, success } = await res2.json();

    if (error2) {
      loading = false;
      error = error2;
      return;
    }

    window.open(success, "_blank");
    window.open(PLATFORM_URL + "/app", "_self");

    loading = false;
  };

  let data = {
    name: "",
    email: "",
    gender: "Selecione um gênero",
    password: "",
    passwordConfirm: "",
    cpf: "",
  };

  let error = false;
  let loading = false;

  const setField = (field, value) => {
    data[field] = value;
  };
</script>

<svelte:head>
  <title>Assinar</title>
</svelte:head>

<Header />
<section
  class="pt-[150px] px-8 md:px-[100px] pb-[100px] flex flex-row items-start justify-around flex-wrap min-h-[110vh]"
>
  <div class="pt-8 w-[100%] md:w-[30%]">
    <div>
      <h3 class="text-2xl mb-8 leading-6 font-medium text-gray-900">
        Informações de cadastro
      </h3>
      <p class="mt-1 text-sm text-gray-500">
        Após criar a conta, você será redirecionado(a) para a página de
        pagamento e para a página inicial da plataforma. Você terá acesso aos
        vídeos e redações após que efetuar o pagamento.
        <br />
        <b>
          O pagamento é feito de forma segura através da plataforma Paypal,
          portanto não coletamos infomações de seu cartão.</b
        >
      </p>
    </div>
  </div>
  <form
    class="pb-[90px] relative flex flex-row flex-wrap w-full md:w-[60%] shadow-md rounded-md p-8 h-auto"
  >
    <div class="w-full md:w-[45%] m-4 flex flex-col gap-2">
      <label for="name">Nome:</label>
      <input
        class="w-full border-gray-300 p-2 border-[1px] rounded-md focus:border-cyan-400"
        value={data.name}
        placeholder="Nome e sobrenome"
        on:change={(e) => setField("name", e.target.value)}
      />
    </div>

    <div class="w-full md:w-[45%] m-4 flex flex-col gap-2">
      <label for="email">Email:</label>
      <input
        class="w-full border-gray-300 p-2 border-[1px] rounded-md focus:border-cyan-400"
        value={data.email}
        placeholder="exemplo@exemplo.com"
        on:change={(e) => setField("email", e.target.value)}
      />
    </div>

    <div class="w-full md:w-[45%] m-4 flex flex-col gap-2">
      <label for="cpf">Cpf:</label>
      <input
        class="w-full border-gray-300 p-2 border-[1px] rounded-md focus:border-cyan-400"
        value={data.cpf}
        placeholder="99999999999"
        on:change={(e) => setField("cpf", e.target.value)}
      />
    </div>

    <div class="w-full md:w-[45%] m-4 flex flex-col gap-2">
      <label for="gender">Gênero:</label>
      <select
        class="w-full border-gray-300 p-2.5 border-[1px] rounded-md focus:border-cyan-400"
        value={data.gender}
        on:change={(e) => setField("gender", e.target.value)}
      >
        <option>Selecione um gênero</option>
        <option value="Masculino">Masculino</option>

        <option value="Feminino">Feminino</option>

        <option value="Outro">Outro</option>
      </select>
    </div>

    <div class="w-full md:w-[45%] m-4 flex flex-col gap-2">
      <label for="pass">Senha:</label>
      <input
        class="w-full border-gray-300 p-2 border-[1px] rounded-md focus:border-cyan-400"
        value={data.password}
        type="password"
        placeholder="*********"
        on:change={(e) => setField("password", e.target.value)}
      />
    </div>

    <div class="w-full md:w-[45%] m-4 flex flex-col gap-2">
      <label for="passConfirm">Confirme a senha:</label>
      <input
        class="w-full border-gray-300 p-2 border-[1px] rounded-md focus:border-cyan-400"
        value={data.passwordConfirm}
        placeholder="********"
        type="password"
        on:change={(e) => setField("passwordConfirm", e.target.value)}
      />
    </div>

    <div
      class="absolute bottom-0 left-0 w-full flex flex-col bg-gray-100 mt-2 pt-4 rounded-md rounded-t-none"
    >
      {#if error}
        <p class="ml-4 mb-2 text-red-500 font-medium">
          {error}
        </p>
      {/if}
      <button
        class="flex flex-row justify-center bg-amber-500 mb-2 ml-4 w-[280px] p-2 border-[1px] rounded-md text-white hover:bg-amber-600"
        type="button"
        disabled={loading}
        on:click={() => register(data)}
      >
        {#if loading}
          <svg
            role="status"
            class="mr-2 w-6 h-6 animate-spin text-white fill-white"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
        {:else}
          Cadastrar
        {/if}
      </button>
    </div>
  </form>
</section>
<footer
  class="flex flex-col items-start w-full p-8 shadow-[0_-6px_6px_-6px_rgba(0,0,0,0.3)] px-4 md:px-[150px] "
>
  <div
    class="flex flex-row items-center w-full flex-wrap gap-8 justify-between"
  >
    <img src="logo3.png" class="w-[70px]" alt="logo" />
    <div>
      <p>Contato:</p>
      <p class="text-cyan-400">bmarquesredacao@hotmail.com</p>
    </div>
    <div>
      <p>Redes sociais:</p>
      <a
        href="https://www.instagram.com/marques_betania"
        target="_blank"
        class="fa fa-instagram text-lg"
      />
      <a
        href="https://www.youtube.com/BetaniaMarques"
        target="_blank"
        class="fa fa-youtube text-lg"
      />
    </div>
  </div>
  <p class="pt-8">
    © Copyright 2019 Betânia Marques - Todos os direitos reservados
  </p>
</footer>

<style lang="postcss" global>
  @tailwind base;
  @tailwind components;
  @tailwind utilities;
</style>
